// Base
// --------------------------------------------------

// Border box for everything
* {
  box-sizing: border-box;
}

html {
  overflow: auto; // added this line
  &.no-scroll {
    overflow: hidden;
  }
}


body {
  overflow-y: scroll; // Always show scrollbar
  min-height: 100vh;
  font-family: $base-font-family;
  color: $base-text-color;
  line-height: $base-line-height;
  text-size-adjust: none;


}

// Use ligatures, kerning and font smoothing
body,
button,
input,
*:before,
*:after {
  font-feature-settings: "kern", "liga", "pnum";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Selected text style
::selection {
  background-color: rgba($brand-color, 0.3);
}
