@charset "utf-8";

@lost flexbox flex;
@lost gutter 20px;

// Variables and mixins
@import "misc/mixins";
@import "misc/variables";
@import "misc/breakpoint";
// Vendor
@import "vendor/normalize";

// Components
@import "components/animation";
@import "components/base";
@import "components/buttons";
@import "components/forms";
@import "components/overlay";
@import "components/helpers";
@import "components/icons";
@import "components/images";
@import "components/lists";
@import "components/modals";
@import "components/navigation";
@import "components/tables";
@import "components/typography";
@import "components/map";
@import "components/grid2";

// Partials
@import "partials/header";
@import "partials/footer";
@import "partials/blocks";
@import "partials/nav";
@import "partials/share";


// Sections
@import "sections/home";

html.sr .load-hidden {
  visibility: hidden;
}

.branded-landing-page-content {
  overflow-x: hidden !important;
  max-width: none;
  margin: 70px auto 0px;
  padding: 0;
}
.htmlfragment.aem-GridColumn {
  padding: 0!important;
}
