.embed__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

}
.desktop__embed__wrapper {
  width: 100%;
  display: none;

  &.show {
    display: flex;
    flex-direction: column;
  }

  @include media($md) {
    display: flex;
    flex-direction: column;
  }

  .embed {
    margin: 0 auto;

  }
}

.mobile__embed__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media($md) {
    display: none;
  }
}