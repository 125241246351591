.quote__container {
  background-color: theme(light-gray);

  .quote__left {
    lost-column: 1/1 1;
    @include media(650px) {
      lost-column: 3/4 2;
    }
  }

  .quote__right {
    lost-column: 1/1 1;
    border-left: none;
    padding-left: 0px;
    @include media(650px) {
      lost-column: 1/4 2;
      border-left: 1px solid rgba(38, 137, 13, .2);
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
      padding-left: 40px;
    }
  }

  .quote {
    max-width: 1012px;
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .author__name {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-top: 21px;
  }

  .quote__text {

    p {
      font-style: normal;
      font-weight: 300;
      @include fluid-type(22px, 26px);
      line-height: 35px;

    }
    &.single {
      max-width: 627px;
    }
  }

  .d_quote {
    & > * {
      margin-top: 0;
      margin-bottom: 26px;
    }

    .author__name {
      margin: 0;
      margin-top: 8px;
    }

  }
}