.overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0;
  opacity: 1;
  transition: opacity .5s ease-in-out;
  z-index: 999999999999;
  &.close {
    top: 100%;
    height: 0;
    opacity: 0;
    transition: opacity .5s ease-in-out;

  }

  @include media($md) {
    padding: 50px 10px;
    position: fixed;
    opacity: 1;


    &.close {
      height: 100%;
      opacity: 0;

    }
  }

  .overlay__window {
    max-width: 890px;
    background-color: #ffffff;
    margin: 0 auto;
    position: relative;
    overflow: scroll;
    //padding: 20px;
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    box-shadow: none;

    @include media($md) {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      height: auto;
      width: auto;
    }

  }

  .overlay__main__content {
    padding: 25px 50px;
    padding-bottom: 100px;
    background-color: #ffffff;
    @include media($lg) {
      padding: 25px 118px;
    }

  }

  .overlay__footer {
    padding: 32px 50px;
    background-color: theme(light-gray);
    text-decoration: none;

    @include media($lg) {
      padding: 32px 118px;
    }

    .footer__title {
      font-weight: bold;
      @include fluid-type(16px, 16px);
      margin-bottom: 24px;
    }

    .footer__card {


      .card__meta {
        @include fluid-type(16px, 16px);
        color: theme(ac-green);
        font-weight: normal;
        margin-top: 20px;
        margin-bottom: 8px;
      }

      .card__title {
        @include fluid-type(18px, 24px);
        line-height: 1.2;
        margin-top: 0;
      }
    }
  }

  .overlay__sub__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 570px;

    &.center {
      margin: 0 auto;
    }
    svg {
      margin-right: 12px;
    }


  }

  .h1 {
    @include fluid-type(20px, 36px);
    max-width: 570px;

  }

  .overlay__summary {
    max-width: 570px;

    p {
      @include fluid-type(14px, 18px);

    }
  }

  .overlay__subtitle__title {
    font-weight: bold;
    @include fluid-type(16px, 16px);
  }

  .overlay__iframe {
    margin-bottom: 18px;
  }

}