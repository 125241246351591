
.pd-header__nav,
nav {
  background-color: $black;
  padding: 0px 2rem !important;

  .menu__icon {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    @include media(995px) {
      display: none;
    }
  }

  .nav__list {

    display: none;

    &.show {
      display: block;
    }
    @include media(995px) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    #dropdown-menu {

      &:hover {
        .factor__list {
          display: block;

        }
      }
    }

    .nav__item {
      margin-right: 30px;
      cursor: pointer;
      position: relative;



      .factor__list {
        display: block;
        position: relative;
        background-color: $black;
        z-index: 999;
        width: 350px;
        top: 10px;
        left: -20px;
        padding: 20px;
        padding-top: 0;



        @include media(995px) {
          top: 30px;
          position: absolute;
          display: none;
        }


        li {
          padding: 6px 0;
        }
      }

      p{
        margin-bottom: 0;
      }

      p,
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $white;
        font-weight: bold;
        font-size: 14px;

        &.parent {
          cursor: auto;
          &:hover {
            color: $white;
          }
        }



        &:hover {
          color: theme(ac-green);
          svg {
            rect,
            path {
              stroke: theme(ac-green);
            }
          }
        }

        svg {
          margin-left: 10px;
        }
      }
    }

    #anchor-link {
      margin-right: 18px;

    }
  }
}