// Typography
// --------------------------------------------------

// Local fonts
// @include font-face("Ionicons", "../fonts/ionicons", $file-formats: eot svg ttf woff);
a {
  text-decoration: none;
  color: $brand-color;
}

// Paragraphs
p,
.p {
  @include fluid-type(18px, 21px);
  margin-top: 0;
  margin-bottom: $paragraph-spacing;
  line-height: 30px;
  word-wrap: break-word;

}


 //Headings
 //Use the heading-style mixin wherever you need heading styles
h1,
.h1,
.wysiwyg h1 {
  @include fluid-type(40px, 70px);
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-text-color;
  font-weight: 300;
  word-wrap: break-word;
}

h2,
.h2,
.wysiwyg h2 {
  @include fluid-type(30px, 40px);
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-text-color;
  font-weight: 400;
  word-wrap: break-word;
}

h3,
.h3,
.wysiwyg h3 {
  @include fluid-type(20px, 25px);
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-text-color;
  font-weight: 400;
  word-wrap: break-word;
}

h4,
.h4,
.wysiwyg h4 {
  @include fluid-type(16px, 23px);
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-text-color;
  font-weight: 400;
  word-wrap: break-word;
}

h5,
.h5,
.wysiwyg h5 {
  @include fluid-type(20px, 20px);
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-text-color;
  font-weight: 700;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5,
.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5 {
  &:first-child {
    margin-top: 0;
  }
}


// Selected text style
::selection {
  background-color: $brand-color;
  color: #fff;
}

// Emphasis
strong, b {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

// Rules
hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $gutter-width 0;
}

// Quotes
.blockquote {
  clear: both;
  width: 100%;
  margin: $base-spacing 0 $base-spacing 0;
  padding-left: $double-spacing;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: calc(100% - 14px);
    top: 7px;
    left: 2px;
    background-color: $brand-color;
  }
}

.blockquote__quote {
  font-style: italic;
  //font-size: $h4-font-size;
  text-indent: -0.4em;
  margin-top: 0;
  margin-bottom: $half-spacing;

  &:before {
    content: "\201C";
  }

  &:after {
    content: "\201D";
  }
}

.blockquote__source {
  font-style: normal;

  &:before {
    content: "\2014";
    margin-right: 0.2em;
  }
}

abbr[title] {
  text-decoration: none;
}
