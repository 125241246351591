.promo__panel__container {
  background-color: theme(light-gray);
  padding-top: 1rem;
  .promo__panel {

    .promo__panel__content {
      lost-column: 1/1 0 0px;

      @include media(900px) {
        lost-column: 1/2 0 0px;


      }
    }

    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    max-width: 950px;
    margin: 0 auto;

    .panel__image {
      height: 100%;
      min-height: 262px;
    }


    .panel__text {
      padding: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .h3 {
        @include fluid-type(17px, 25px);
        margin-bottom: 16px;
      }

      .panel__body {

        p {

          font-size: 12px;
          line-height: 1.5em;
          @include media(400px) {
            @include fluid-type(12px, 15px);
          }


          color: theme(gray);
        }
      }

      .btn--service {
        border-color: $brand-color;
        background-color: $brand-color;
        border-width: 2px;
        &:hover {
          border-color: $brand-color;
          background-color: transparent;
          color: $brand-color;
        }
      }

    }
  }

}