.contacts__container {

  .contacts {
    .contact {
      lost-column: 1/1 1;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0px;
      }

      @include media(400px) {
        lost-column: 1/2 2;
        &:nth-last-child(-n+2) {
          margin-bottom: 0px;
        }
      }

      @include media(520px) {
        lost-column: 1/3 3;
        &:nth-last-child(-n+3) {
          margin-bottom: 0px;
        }
      }


      @include media(640px) {
        lost-column: 1/4 4;
        &:nth-last-child(-n+4) {
          margin-bottom: 0px;
        }
      }

      .contact__details {
        display: flex;
        flex-direction: column;
        margin-top: 16px;


        .contact__section {
          & > * {
            margin-top: 0;
            margin-bottom: 8px;
          }
        }


        .contact__name {
          @include fluid-type(20px, 20px);
          font-weight: bold;
        }

        .contact__role,
        .contact__number{
          @include fluid-type(17px, 17px);
          line-height: 1;

        }
        .contact__list {
          list-style: none;
          display: flex;
          flex-direction: column;

          li {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;

            }
            a {
              @include fluid-type(17px, 17px);
              font-weight: 600;
              color: theme(ac-green);

              &:hover {
                text-decoration: underline;

                svg {
                  circle {
                    fill: theme(ac-green);
                  }
                  path {
                    fill: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}