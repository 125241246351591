.cta2col__container {


  .cta {
    lost-column: 1/1 1;
    margin-bottom: 30px;
    border-radius: 4px;
    background-color: theme(ac-green);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: initial;

    &.pd-blue {
      background-color: theme(ac-blue);

      .cta__footer {
        background-color: theme(ac-dark-blue);
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }

    @include media(620px) {
      lost-column: 1/2 2;
      &:nth-last-child(-n+2) {
        margin-bottom: 0px;
      }
    }

    .cta__footer {
      padding: 20px 25px;
      background-color: theme(dark-green);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .cta__buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .cta__body {
      display: flex;
      flex-direction: column;
      padding: 40px 30px;

      @include media($lg) {
        flex-direction: row;
        padding: 40px 20px;

      }
      .cta__icon {
        margin-right: 20px;

        svg {
          width: 56px;
        }


        @include media($xlg) {
          margin-right: 40px;

          svg {
            width: 76px;
          }

        }

      }

      .cta__text {

        .meta__title {
          color: #ffffff;
          @include fluid-type(20px, 20px);
          margin-bottom: 6px;
          font-weight: bold;
        }

        .cta__title {
          color: #ffffff;
          margin-top: 0;
          @include fluid-type(16px, 23px);

        }
      }
    }
  }
}