.recommendations__container {

  background-color: theme(light-gray);
  h3 {
    font-weight: bold;
    @include fluid-type(22px, 22px);
    margin-bottom: 34px
  }

  .summary {
    max-width: 870px;
    p {
      font-weight: normal;
      @include fluid-type(19px, 19px);
    }

  }
  .panel__wrapper {
    margin-top: 20px;
  }


  .panel {
    lost-column: 1/1 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    margin-bottom: 30px;

    @include media(900px) {
      lost-column: 1/2 2;

      &:nth-last-child(-n+2) {
        margin-bottom: 0px;
      }
    }

    .panel__content {
      padding: 20px;

      @include media($lg) {
        padding: 40px 30px;
      }

      .h2 {
        @include fluid-type(24px, 30px);
        margin-bottom: 34px
      }

      .recommendations__list {
        li {

          width: 100%;
          border: 1px solid theme(light-green);
          border-radius: 4px;
          margin-bottom: 10px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0px;
          }

          &:hover {
            background-color: theme(light-green);

            .item__content {
              .item__icon {
                svg {
                  #check {
                    stroke-dasharray: 450;
                    stroke-dashoffset: 450;
                    animation: draw 4.5s linear forwards;

                  }
                }
              }
            }
          }


          .item__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 18px;

            .item__icon {
              width: 29px;
              height: 29px;

              svg {
                #check {

                }
              }

            }
            .item__title {
              @include fluid-type(19px, 17px);
              padding-left: 18px;
            }
          }

        }
      }
    }


  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}