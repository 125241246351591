.share__container {
  .h3 {
    text-align: center;
  }

  .share__list {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      display: inline-block;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      a {

        &:hover {
          svg {
            path {
              fill: rgba(38,137,13,0.7);
            }
          }
        }
      }
    }
  }
}