.service__container {

  background-color: $black;
  color: $white;
  .services__heading {
    color: inherit;
    @include fluid-type(20px, 40px);

    &.single {
      padding-top: 1rem;
      @include media($md) {
        padding-top: 4rem;
      }
    }
  }

  .service__image__wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 380px;

    img {
      width: 100%
    }


  }

  .h3 {
    color: inherit;
    @include fluid-type(24px, 28px);
    margin-bottom: 10px;
  }

  .summary {
    max-width: 526px;
    p {
      margin-bottom: 20px;
      @include fluid-type(16px, 20px);
    }
  }
}