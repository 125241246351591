

.container {
  padding: 1rem;

  @include media($md) {
    padding: 1.5rem;
  }

  @include media($lg) {
    padding: 2rem;
  }
  //lost-utility: edit;

  &.flush {
    padding: 0;
  }

  &.container-tnp {
    padding: 3rem 1rem 3rem 1rem;
    @include media($md) {
      padding: 0 1.5rem;
    }

    @include media($lg) {
      padding: 0 4rem;
    }
  }

  &.container-bnp {
    padding: 3rem 1rem 0 1rem;
    @include media($md) {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }

    @include media($lg) {
      padding: 4rem 4rem 0 4rem;
    }
  }

  &.container-tp {
    padding: 3rem 1rem 3rem 1rem;
    @include media($md) {
      padding: 1.5rem;
    }

    @include media($lg) {
      padding: 4.5rem 4rem 4.5rem 4rem;
    }

    &.container-tnp {
      padding: 3rem 1rem 3rem 1rem;
      @include media($md) {
        padding: 0 1.5rem;
      }

      @include media($lg) {
        padding: 0 4rem;
      }
    }

  }

  &.remove-padding {
    padding: 3rem 1rem 3rem 1rem;
    @include media($md) {
      padding: 0 1.5rem;
    }

    @include media($lg) {
      padding: 1rem 4rem;
    }
  }
}

.row {
  lost-center: $max-width;
  lost-utility: clearfix;

  &.full {
    max-width: none;
  }

  &.m-reverse {
    flex-direction: column-reverse;

    @include media($md) {
      flex-direction: row;

    }
  }
}


@for $i from 1 through 12 {
  .item-#{$i} {
    lost-column: 1/1 1;

    @include media($md) {
      lost-column: 1/$i $i;
    }
  }
}

.item-half {
  lost-column: 1/1 1;

  @include media($md) {
    lost-column: 1/2 2 50px;
  }
}





