.text__container {
  background-color: $white;

  &.white {
    background-color: $white;

  }

  &.grey {
    background-color: #f8f8f8;

  }
}