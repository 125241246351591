.casestudies__container {

  .case__overlay {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 180px;
    & > * {
      margin-top: 0;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;

      }
    }

    .h1 {
      margin-bottom: 30px;

    }
    &__meta {
      font-weight: bold;
      font-size: 16px;

      color: #000000;
    }
    .case__body {
      p {

        font-weight: normal;
        font-size: 18px;
        line-height: 25px;

        color: #000000;
      }
    }
  }

  .case {
    lost-column: 1/1 1;
    margin-bottom: 30px;
    cursor: pointer;

    &:hover {
      .h3 {
        color: theme(ac-green);
      }
    }


    &:last-child {
      margin-bottom: 0px;
    }

    @include media($md) {
      lost-column: 1/2 2;
      &:nth-last-child(-n+2) {
        margin-bottom: 0px;
      }
    }

    .case__content {
      margin-top: 20px;

      & > * {
        margin-top: 0;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;

        }
      }
      .case__meta {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: theme(ac-green);
        margin-bottom: 0;
      }
      .h3 {
        @include fluid-type(30px, 36px);
        line-height: 49px;
      }

      .case__summary {

        p {
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;

          color: #767676;

        }
      }
    }


  }
}