// Buttons
// --------------------------------------------------

.btn {
  display: inline-block;
  padding: $half-spacing $base-spacing;
  color: $action-color;
  background-color: transparent;
  font-family: $base-font-family;
  font-weight: normal;
  font-size: 20px;
  border-radius: $base-border-radius;
  cursor: pointer;
  transition-property: color, background-color, border-color, width;
  transition: $base-duration $ease-out-quint;
  margin-bottom: 0;
  border: 3px solid transparent;
  margin-right: $half-spacing;
  margin-bottom: $half-spacing;
  line-height: 1.2;

  &:hover,
  &:focus {
    color: $action-color;
    outline: none;
  }

  &:active {
    color: $action-color;
  }

  &:last-child {
    margin-right: 0;
  }
}

// Sizes
.btn--small {
  padding: 0.5em 1em;
  display: inline-block;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  background-color: $brand-color;
  color: #fff;

  &:hover,
  &:focus {
    background-color: $brand-color;
    color: #fff;
  }

  &:active {
    background-color: $brand-color;
    color: #fff;
  }
}

.btn--large {
  padding: 1em 3em;
}

// Layout
.btn--block {
  width: 100%;
  display: block;
}

// Types
.btn--primary {
  background-color: $brand-color;
  color: #fff;

  &:hover,
  &:focus {
    background-color: $brand-color;
    color: #fff;
  }

  &:active {
    background-color: $brand-color;
    color: #fff;
  }
}

.btn--home {
  border-color: $white;
  background-color: transparent;
  color: $white;
  border-width: 1px;
  display: inline-block;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  padding: 0.25em 1em;
  &:hover,
  &:active,
  &:focus {
    border-color: $brand-color;
    background-color: $brand-color;
    color: #ffffff;
  }
}

.btn--outline {
  border-color: $brand-color;
  background-color: transparent;
  color: $brand-color;
  border-width: 2px;
  display: block;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 0.25em 1em;
  &:hover,
  &:active,
  &:focus {
    border-color: $brand-color;
    background-color: $brand-color;
    color: #ffffff;
  }

}

.btn--service {
  border-color: $brand-color;
  background-color: $brand-color;
  color: $white;
  border-width: 2px;
  margin-right: 0;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 14px 50px;

  @include media($md) {
    padding: 14px 60px;

  }
  @include media(1190px) {
    margin-bottom: 10px;

  }
  &:hover,
  &:active,
  &:focus {
    border-color: $white;
    background-color: transparent;
    color: $white;
  }

  &.center {
    margin: 0 auto;
    margin-top: 30px;

    &:hover,
    &:active,
    &:focus {
      border-color: $brand-color;
      background-color: transparent;
      color: $brand-color;
    }
  }

}

.btn--quote {
  border-color: $brand-color;
  background-color: $brand-color;
  color: $white;
  border-width: 2px;
  margin-right: 0;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 14px 30px;

  &:hover,
  &:active,
  &:focus {
    border-color: #333;
    background-color: transparent;
    color: #333;
  }

}

.close__button__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 25px 25px 0px 25px;;

}

.download__button__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn--download {
  border-color: $brand-color;
  background-color: $brand-color;
  color: $white;
  border-width: 2px;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 6px 16px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  &.recommendation {
    margin-right: 12px;

    svg {
      margin-right: 0px;
    }


  }

  svg {
    margin-right: 10px;


  }
  &:hover {
    border-color: $brand-color;
    background-color: transparent;
    color: $brand-color;

    svg {
      path {
        fill: $brand-color;
      }
    }
  }

  &:active,
  &:focus, {
    border-color: $brand-color;
    background-color: $brand-color;
    color: $white;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  &.center {
    margin: 0 auto;
    margin-top: 30px;

    &:hover,
    &:active,
    &:focus {
      border-color: $brand-color;
      background-color: transparent;
      color: $brand-color;
    }
  }

}

.btn--close {
  border-color: $brand-color;
  background-color: $brand-color;
  color: $white;
  border-width: 2px;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 6px 16px 6px 8px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 10px;
  }
  &:hover {
    border-color: $brand-color;
    background-color: transparent;
    color: $brand-color;

    svg {
      path {
        stroke: $brand-color;
      }
    }
  }

  &.center {
    margin: 0 auto;
    margin-top: 30px;

    &:hover,
    &:active,
    &:focus {
      border-color: $brand-color;
      background-color: transparent;
      color: $brand-color;
    }
  }

}


.btn--link {
  padding-left: 0;
  padding-right: 0;
}

// States
.btn--disabled,
.btn[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.btn--loading {
  pointer-events: none;
  text-indent: -9999rem;
}

.btn--icon {
  @include fluid-type(16px, 24px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $white;
    color: theme(ac-green);

    svg {
      path {
        stroke: theme(ac-green);
      }
    }
  }

  &:active {
    background-color: theme(ac-green);
    color: $white;

    svg {
      path {
        stroke: $white;
      }
    }
  }

  .icon {
    margin-right: 8px;
    width: 26px;
    height: 26px;
    align-content: center;
    justify-content: center;

    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.btn--cta {
  @include fluid-type(14px, 14px);
  padding: 0.5em 1em;
  display: inline-block;
  line-height: 27px;
  text-align: center;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.75);
  color: theme(dark-green);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 2px solid rgba(255, 255, 255, 0.75);

  &.primary {
    padding: 8px 18px;
    background-color: $white;
    border: 2px solid $white;

  }

  &.pd-blue {
    color: theme(ac-dark-blue);

    svg {
      path {
        fill: theme(ac-dark-blue);
      }
    }


    &:hover,
    &:focus {
      background-color: theme(ac-dark-blue);
      color: $white;
      border: 2px solid $white;


      svg {
        path {
          fill: $white;
        }
      }
    }

    &:active {
      background-color: $white;
      color: theme(ac-dark-blue);

      svg {
        path {
          fill: theme(ac-dark-blue);
        }
      }
    }
  }


  &:hover,
  &:focus {
    background-color: theme(ac-green);
    color: $white;
    border: 2px solid $white;


    svg {
      path {
        fill: $white;
      }
    }
  }

  &:active {
    background-color: $white;
    color: theme(ac-green);

    svg {
      path {
        fill: theme(ac-green);
      }
    }
  }


  .icon {
    margin-right: 6px;
    width: 17px;
    height: 17px;
    display: flex;
    align-content: center;
    justify-content: center;

    svg {
      width: 17px;
      height: 17px;
    }
  }
}
