.insights__container {

  .insight__col {
    lost-column: 1/1 1;
    @include media(700px) {
      lost-column: 1/2 2;

    }
  }


  .insight__wrapper {
    lost-column: 1/2 2;
  }

  .insight {
    text-decoration: none;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;




    &.mini {
      lost-column: 1/1 1;
      @include media($lg) {
        lost-column: 1/2 2;

      }
      flex-direction: row;

      .insight__title {
        margin-bottom: 34px;
      }

      .insight__type {
        margin-top: 0px;
        margin-bottom: 0;
      }

      .insight__image {
        max-width: none;
        width: auto;
        margin-right: 10px;
        height: 100%;
        @include media($lg) {
          max-width: 176px;
          width: 176px;
        }

        .btn--play {
          height: 40px;
          width: 40px;
          left: 50%;
          margin-left: -20px;
          top: 50%;
          margin-top: -20px;
        }

      }

      .insight__content {
        lost-column: 2/3 1;

      }

      @include media($md) {
        &:nth-last-child(-n+2) {
          margin-bottom: 20px;
        }
      }

    }

    @include media($md) {
      &:nth-last-child(-n+2) {
        margin-bottom: 0px;
      }
    }

    &:hover {
      .insight__title {
        color: theme(ac-green);
      }

      .btn--play {
        background-color: $white;
        color: theme(ac-green);

        svg {
          path {
            stroke: theme(ac-green);
          }
        }
      }
    }
  }
  .insight__image {
    position: relative;
    height: 100%;
  }

  .btn--play {
    position: absolute;
    bottom: 18px;
    right: 25px;
    display: none;
    text-decoration: none;
    font-size: 22px;
    z-index: 1;

    @include media(800px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }


      &:active {
      background-color: theme(ac-green);
      color: $white;

      svg {
        path {
          stroke: $white;
        }
      }
    }

    .text {
      display: none;
      @include media($md) {
        display: block;
      }
    }

    .icon {
      margin-right: 0px;

      @include media($md) {
        margin-right: 10px;
      }
      width: 22px;
      height: 22px;
      align-content: center;
      justify-content: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .insight__type {
    @include fluid-type(16px, 16px);
    color: theme(ac-green);
    margin-top: 14px;
    margin-bottom: 0;
  }
  .insight__title {
    @include fluid-type(18px, 22px);
    margin-top: 8px;
    font-weight: 500;
    margin-bottom: 34px;
    &.large {
      @include fluid-type(18px, 36px);

    }
  }
}