// Forms
// --------------------------------------------------

input,
label,
select {
  display: block;
}

//input { -webkit-appearance:none; -webkit-border-radius:0; }


fieldset {
  border: none;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
  position: relative;
  margin-bottom: 20px;

}


label {
  font-family: $heading-font-family;
  font-weight: 500;
  font-size: 16px;
  color: $black;
  margin-bottom: 6px;
  &.required::after {
    content: "*";
  }
}

select[multiple=multiple],
textarea {
  background-color: $background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: none;
  box-sizing: border-box;
  font-family: $base-font-family;
  margin-bottom: $half-spacing;
  padding: 0.3em 0.7em 0.5em 0.7em;
  transition: border-color;
  width: 100%;

  &:hover {
    border-color: darken($border-color, 10%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: none;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $half-spacing;
}

input[type="file"] {
  padding-bottom: $half-spacing;
  width: 100%;
}

select {
  max-width: 100%;
  width: auto;
}

input {
  width: 100%;
  background-color: $white;
  border-radius: $base-border-radius;
  padding: 18px 16px;
  outline: none;
  color: $black;
  font-weight: normal;
  border: 1px solid #E8E8E8;
  font-size: 16px;
  &::placeholder {
    color: #E0E0E0;
    font-size: 14px;

  }
  &::-webkit-input-placeholder {
    color: #E0E0E0;
    font-size: 14px;
  }
  &::-moz-placeholder {
    color: #E0E0E0;
    font-size: 14px;
  }

}

.heading {
  margin-bottom: 20px;
  font-size: 18px;
}


.control {
  display: inline-flex;
  flex-direction: row;
  height: 24px;
  align-items: center;
  margin-right: 0;
  color: $black;
  margin-bottom: 10px;
  width: 100%;
  label {
    font-weight: normal;
    cursor: pointer;
    font-size: 14px;

  }
  @include media($sm) {
    margin-right: 10px;

  }

  position: relative;
  padding-left: 34px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;

}
.control__indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #E8E8E8;
  background: transparent !important;

}
.control--checkbox .control__indicator {
  border-radius: 4px;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  border: 1px solid #E8E8E8;

}
.control input:checked ~ .control__indicator {
  background: theme(ac-green);
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: theme(ac-green);
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}

.control--checkbox .control__indicator:after {
  left: 3px;
  top: 3px;
  height: 16px;
  width: 16px;
  border-radius: 2px;

  background: theme(ac-green);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  background: theme(ac-green);
}

select {

  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2c3e50;
  background-image: none;
}

select option { color: theme(ac-green); }

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: .25em;
  @include shadow(shadow(panel));
  border: 1px solid #E8E8E8;

}
select {
  flex: 1;
  cursor: pointer;
  width: 100%;
  background-color: $white;
  border-radius: $base-border-radius;
  border: none;
  padding: 18px 16px;
  outline: none;
  color: theme(ac-green);
  font-weight: 700;
  border: 1px solid #E8E8E8;
  font-size: 16pxs;

}

select:invalid,
select option[value=""] {
  color: #E0E0E0;
  font-size: 14px;
}
/* Arrow */
.select::after {
  content: url('../img/down-arrow.svg');
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  padding-top: 20px;
  cursor: pointer;
  pointer-events: none;
  transition: .25s all ease;
  height: calc(100% - 20px);
  width: 24px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
/* Transition */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}