.video__container {

  .btn--service {
    &.center {
      margin-top: 10px;
    }
  }

  h3 {
    font-weight: bold;
    @include fluid-type(22px, 22px);
    margin-bottom: 24px
  }

  .video__poster__image {

    &:hover {
      .btn--play {
        background-color: $white;
        color: theme(ac-green);

        svg {
          path {
            stroke: theme(ac-green);
          }
        }
      }
    }
    .btn--play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-decoration: none;
      font-size: 22px;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;


      &:active {
        background-color: theme(ac-green);
        color: $white;

        svg {
          path {
            stroke: $white;
          }
        }
      }

      .text {
        display: none;
        @include media($md) {
          display: block;
        }
      }

      .icon {
        margin-right: 0px;

        @include media($md) {
          margin-right: 10px;
        }
        width: 22px;
        height: 22px;
        align-content: center;
        justify-content: center;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .video__poster__image,
  .video__embed__container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-top: 30px;

    @include media($md) {
      margin-top: 0px;
    }

    img,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }



  }

  .video__caption {
    @include fluid-type(18px, 22px);

    font-weight: normal;
    line-height: 30px;
    margin-top: 10px;
    color: #000000;

    @include media($md) {
      margin-top: 20px;
    }
  }
}