// Header
// --------------------------------------------------
.header__main {
  background-color: $black;

}
.frag-header {
  height: 100vh;
  position: relative;
  max-height: 612px;
  overflow: hidden;

  .background__overlay {
    display: none;

    @include media($md) {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

  }

  .mobile__header__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 56px;
    @include media($md) {
      display: none;
    }
  }
  .background__video__container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform-style: preserve-3d;
    max-height: 612px;

    @include media($md) {
      display: block;
    }


    video {
      position: absolute;
      z-index: 1;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $black;
        opacity: 0.8;
      }
    }
  }


  .header__inner {
    lost-column: 1/1 1;
    min-width: none;
    margin-top: 100px;
    @include media(650px) {
      lost-column: 1/2 2;
      min-width: 500px;
      margin-top: 0px;

    }
    z-index: 1;

  }
  .header__content {
    height: 100%;
    align-items: center;
    margin-top: 56px;

    @include media(650px) {

      margin-top: 0px;

    }
  }

  .h1 {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.1;

  }

  .summary {
    p {
      @include fluid-type(20px, 28px);
      color: #ffffff;
      margin-bottom: 30px;

    }

  }

  .header__icon {
    lost-column: 1/1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      rect {
        fill: $white;
      }

      path {
        stroke: theme(ac-green);
      }
    }
  }

  .header__icon {
    position: absolute;
    bottom: 40px;
    left: 50%;
    right: 50%;
    transform: translate(50%, 0);
  }



}

.header__logo {
  display: block;
  height: 100%;
}
