.intro__container {
  background-color: theme(light-gray);
}

.body {
  max-width: 950px;

  &.center {
    margin: 0 auto;
  }

  p {
    @include fluid-type(18px, 19px);
  }

  &.large {
    p {
      @include fluid-type(18px, 21px);
    }
  }

  &.single {
    max-width: 820px;
    margin: 0 auto;

    &.remove-padding {
      margin: 0;
    }
  }
}