.success__container {
  background-color: theme(light-gray);

  .factors__container {
    .panel {
      lost-column: 1/1 1;
      background-color: $white;
      @include shadow(shadow(panel));
      border-radius: 6px;
      text-decoration: none;
      color: black;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      transition-duration: 0.5s;

      &:last-child {
        margin-bottom: 0px;
      }

      @include media($md) {
        lost-column: 1/2 2;
        &:nth-last-child(-n+2) {
          margin-bottom: 0px;
        }
      }

      @include media($lg) {
        lost-column: 1/3 3;
        &:nth-last-child(-n+3) {
          margin-bottom: 0px;
        }
      }

      @include media($xlg) {
        lost-column: 1/4 4;
        &:nth-last-child(-n+4) {
          margin-bottom: 0px;
        }
      }


      &:hover {
        transform: scale(1.05);
      }
      .image__container {
        height: 230px;
        max-height: 230px;
        border-radius: 6px 6px 0px 0px; /* top left, top right, bottom right, bottom left */
        position: relative;
        .image__overlay {
          background: linear-gradient(89.63deg, rgba(0, 0, 0, 0.464) 3.14%, rgba(0, 0, 0, 0) 99.37%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
        }
      }

      .content__container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        .content {
          display: flex;
          flex-direction: column;
          height: 100%;
          .h3 {
            @include fluid-type(17px, 25px);
            margin-bottom: 16px;
          }

          .summary {

            p {

              font-size: 12px;
              line-height: 1.5em;
              @include media(400px) {
                @include fluid-type(12px, 15px);
              }


              color: theme(gray);
            }
          }



        }
        .link {
          display: flex;
          flex-direction: row;
          span {
            color: theme(ac-green);
            @include fluid-type(12px, 14px);
            font-weight: 600;
          }

          .icon {
            height: 10px;
            width: 5px;
            margin-left: 10px;
          }
        }
      }
    }
  }

}